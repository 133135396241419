import $ from 'jquery'
import 'select2/dist/css/select2.css'
import select2 from 'select2'

window.removeTimesheetEntry = function(link) {
  $(link).prev("input[type=hidden]").val("1");
  $(link).closest(".card").hide(500);
}

window.addTimesheetEntry = function(link, content) {
  var new_id = new Date().getTime();
  var regexp = new RegExp("new_timesheet_entry", "g")

  $(link).parent('.formtastic').children('.card').last().after(content.replace(regexp, new_id));
  loadTimesheetFormSelect2();
}

window.loadTimesheetFormSelect2 = function() {
  $('select.hours_dropdown').select2({
    placeholder: "Select Hours",
    width: '100%',
    allowClear: true,
    minimumResultsForSearch: -1,
  });

  $('select.jobcode_dropdown').select2({
    placeholder: "Select a job",
    width: '100%',
    allowClear: true,
    minimumResultsForSearch: 1,
  });

  $('select.jobcode_dropdown.disabled').prop('disabled', true);

  if( /iPhone|iPod|iPad/i.test(navigator.userAgent) ) {
    $('select').on('select2-opening', function() {
      $('select.hours_dropdown').siblings('.select2-container').find('.select2-search, .select2-focusser').remove();
      $('select.jobcode_dropdown').siblings('.select2-container').find('.select2-search, .select2-focusser').remove();
    });
  }

}

window.addEventListener('DOMContentLoaded', () => {
  loadTimesheetFormSelect2();
})
